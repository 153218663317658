<template>
  <div>
    <!-- ** setups performance section start -->

    <b-row>
      <!-- ** title start -->
      <b-col cols="12">
        <h4 class="mb-1">
          {{ $t("SetupsByStatus") }}
        </h4>
      </b-col>
      <!-- title end ** -->

      <!-- ** performance card start -->
      <b-col
        v-for="(p, i) in setupsByStatus"
        :key="i"
        cols="12"
        lg="3"
      >
        <a @click="$router.push(p.route)">
          <statistic-card-horizontal
            :icon="p.icon"
            :statistic="p.statistic"
            :statistic-title="$t(p.statisticTitle)"
            :color="p.color"
            style="max-width: 100%"
          />
        </a>
      </b-col>
      <!-- performance card end ** -->
    </b-row>

    <!-- ** campaign performance section start -->
    <b-row>
      <!-- ** title start -->
      <b-col cols="12">
        <h4 class="mb-1">
          {{ $t("Campaign Performance") }}
        </h4>
      </b-col>
      <!-- title end ** -->

      <!-- ** performance card start -->
      <b-col
        v-for="(p, i) in performanceMetrics"
        :key="i"
        cols="12"
        lg="3"
      >
        <a @click="p.name == 'addlocations' ? showcampaignsMap() : p.clickable ? $router.push(p.route) : ''">
          <statistic-card-horizontal
            :icon="p.icon"
            :statistic="p.statistic"
            :statistic-title="$t(p.statisticTitle)"
            :color="p.color"
            style="max-width: 100%"
          />
        </a>
      </b-col>
      <!-- performance card end ** -->
    </b-row>
    <!-- compaign performance section end ** -->

    <!-- ** partnersInformation card start title -->

    <b-row v-if="isAdmin">
      <!-- ** title start -->
      <b-col cols="12">
        <h4 class="mb-1">
          {{ $t("Partners Information") }}
        </h4>
      </b-col>
      <!-- title end ** -->

      <!-- ** campaigns status start -->
      <b-col
        v-for="(s, i) in partnersInformation"
        :key="i"
      >
        <a @click="$router.push(s.route)">
          <statistic-card-vertical
            class="text-capitalize text-truncate"
            :icon="s.icon"
            :color="s.color"
            :statistic="s.count"
            :statistic-title="s.title"
            style="max-width: 100%"
          />
        </a>
      </b-col>

      <!-- partnersInformation card end ** -->
    </b-row>

    <b-row>
      <!-- ** status section start -->
      <b-col
        cols="12"
        lg="12"
      >
        <b-row>
          <!-- ** title start -->
          <b-col cols="12">
            <h4 class="mb-1">
              {{ $t("Campaigns Information") }}
            </h4>
          </b-col>
          <!-- title end ** -->

          <!-- ** campaigns status start -->
          <b-col
            v-for="(s, i) in campaignsStatus"
            :key="i"
          >
            <a @click="$router.push(s.route)">
              <statistic-card-vertical
                class="text-capitalize text-truncate"
                :icon="s.icon"
                :color="s.color"
                :statistic="s.count"
                :statistic-title="s.title"
                style="max-width: 100%"
              />
            </a>
          </b-col>
          <!-- campaigns status end ** -->
        </b-row>
      </b-col>
      <!-- status section end ** -->

      <!-- ** map section start -->
      <b-col cols="12">
        <b-card>
          <!-- ** title start -->
          <b-card-title>
            {{ $t("Map") }}
          </b-card-title>
          <!-- title end ** -->
          <b-card-body>
            <!-- ** map start -->
            <leafletMap
              :height="'600px'"
              :zoom="11"
              :locations="locations"
              :reset-map-zoom-op="true"
              :geosearch-op="true"
              :marker="true"
              :tooltip="true"
              :popup="true"
              :locations-op="locations"
              :center="[24.6436, 46.7253]"
            />

            <!-- title end ** -->
          </b-card-body>
        </b-card>
      </b-col>
      <!-- map section end ** -->
    </b-row>

    <b-modal
      v-model="campaignsModal"
      size="lg"
      hide-footer
    >
      <template #modal-header="{ close }">
        <span> {{ $t("Map") }} </span>
      </template>
      <div class="d-block">
        <b-row>
          <b-col cols="12">
            <!-- ** title start -->

            <!-- ** map start -->
            <leafletMap
              :locations="locations"
              :reset-map-zoom-op="true"
              :geosearch-op="true"
              :marker="true"
              :tooltip="true"
              :popup="true"
              :locations-op="locations"
              :height="'500px'"
              :zoom="10"
            />

            <!-- title end ** -->
          </b-col>
        </b-row>
      </div>
      <b-button
        class="mt-3"
        block
        @click="campaignsModal = false"
      >{{
        $t("close")
      }}</b-button>
    </b-modal>
  </div>
</template>

<script>
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'

import store from '@/store/'
import useJwt from '@/auth/useJwt'
import leafletMap from '@/@core/components/leaflet-map/leafletMap.vue'

export default {
  components: {
    StatisticCardHorizontal,
    StatisticCardVertical,
    leafletMap,
  },
  data() {
    return {
      campaignsModal: false,
      locations: [],
      partnerStats: {
        inactiveCount: 0,
        hasNoActiveCampaignsCount: 0,
        hasActiveCampaignsCount: 0,
        totalCount: 0,
        activeCount: 0,
      },
      campaignsStats: {
        totalActiveCampaigns: 0,
        totalActiveCampaignsScreens: 0,
        totalCampaigns: 0,
        totalCreatedCampaigns: 0,
        totalPendingCampaigns: 0,
        totalCampaignsPublishReady: 0,
        totalCampaignsPublishing: 0,
        totalCampaignsRejected: 0,
        totalCampaignsLocations: 0,
      },
      assetsStats: {
        totalScreenCount: 0,
        connectedCount: 0,
        disconnectedCount: 0,
      },
    }
  },
  computed: {
    isAdmin() {
      return this.$store.state.app.userRole === 'Admin'
    },
    screensNoCampaign() {
      const flattenScreens = this.assetsStats.totalScreenCount - this.campaignsStats.totalActiveCampaignsScreens
      if (flattenScreens < 0) {
        return 0
      }
      return flattenScreens
    },
    campaignsStatus() {
      return [
        {
          title: this.$t('created'),
          icon: 'CheckIcon',
          color: 'success',
          count: this.campaignsStats.totalCreatedCampaigns,
          route: '/campaigns?status=Pending',
        },
        {
          title: this.$t('pending approvals'),
          icon: 'LoaderIcon',
          color: 'warning',
          count: this.campaignsStats.totalPendingCampaigns,
          route: '/campaigns?status=WaitingApproval',
        },
        {
          title: this.$t('ready for publish'),
          icon: 'UploadCloudIcon',
          color: 'secondary',
          count: this.campaignsStats.totalCampaignsPublishReady,
          route: '/campaigns?status=PublishReady',
        },
        {
          title: this.$t('published'),
          icon: 'CloudIcon',
          color: 'info',
          count: this.campaignsStats.totalCampaignsPublishing,
          route: '/campaigns?status=Publishing',
        },
        {
          title: this.$t('rejected'),
          icon: 'SlashIcon',
          color: 'danger',
          count: this.campaignsStats.totalCampaignsRejected,
          route: '/campaigns?status=ChangeMaterial',
        },
      ]
    },
    setupsByStatus() {
      return [
        {
          icon: 'CloudIcon',
          statistic: this.assetsStats.totalScreenCount,
          statisticTitle: 'AllScreens',
          color: 'danger',
          route: '/setups',
        },
        {
          icon: 'CloudOffIcon',
          statistic: this.assetsStats.disconnectedCount,
          statisticTitle: 'Disconnected',
          color: 'danger',
          route: '/setups?status=Disconnected',
        },
        {
          icon: 'RssIcon',
          statistic: this.assetsStats.connectedCount,
          statisticTitle: 'Connected',
          color: 'warning',
          route: '/setups',
        },
        {
          icon: 'RssIcon',
          statistic: this.screensNoCampaign,
          statisticTitle: 'screensWithOutCampagin',
          color: 'warning',
          route: '/setups',
        },
      ]
    },
    partnersInformation() {
      return [
        {
          title: this.$t('Active Partners'),
          subtitle: this.$t('count'),
          icon: 'UserCheckIcon',
          color: 'success',
          count: this.partnerStats.activeCount,
          route: '/partners',
        },
        {
          title: this.$t('Inactive Partners'),
          subtitle: this.$t('count'),
          icon: 'UserXIcon',
          color: 'danger',
          count: this.partnerStats.inactiveCount,
          route: '/partners',
        },

        {
          title: this.$t('Total Partners'),
          subtitle: this.$t('count'),
          icon: 'RssIcon',
          color: 'info',
          count: this.partnerStats.totalCount,
          route: '/partners',
        },
        {
          title: this.$t('PartnersWithAd'),
          subtitle: this.$t('count'),
          icon: 'RssIcon',
          color: 'info',
          count: this.partnerStats.hasActiveCampaignsCount,
          route: '/partners',
        },
        {
          title: this.$t('PartnersWithoutAd'),
          subtitle: this.$t('count'),
          icon: 'RssIcon',
          color: 'info',
          count: this.partnerStats.hasNoActiveCampaignsCount,
          route: '/partners',
        },
      ]
    },
    performanceMetrics() {
      return [
        {
          icon: 'CastIcon',
          statistic: this.campaignsStats.totalActiveCampaigns,
          statisticTitle: 'Campaigns Running',
          color: 'danger',
          route: '/campaigns',
          clickable: true,
        },
        {
          icon: 'TvIcon',
          statistic: this.campaignsStats.totalActiveCampaignsScreens,
          statisticTitle: 'screensHasCampaign',
          color: 'primary',
          route: '/campaigns',
          clickable: true,
        },
        {
          icon: 'TvIcon',
          statistic: this.campaignsStats.totalCampaigns,
          statisticTitle: 'AllCampaigns',
          color: 'primary',
          route: '/campaigns',
          clickable: true,
        },
        {
          icon: 'RssIcon',
          statistic: this.campaignsStats.totalCampaignsLocations,
          statisticTitle: 'campaignLocations',
          color: 'success',
          name: 'addlocations',
          route: '/addlocations',
          clickable: false,
        },
      ]
    },
  },
  created() {
    this.syncApi()
  },
  methods: {
    showcampaignsMap() {
      this.campaignsModal = true
    },
    getLocations() {
      useJwt
        .get('/qasset/api/domain/locations')
        .then(({ data }) => {
          this.locations = data.data.locations
          store.commit('app/UPDATE_LOCATIONS', this.locations)
        })
        .catch(e => console.error(e))
        .finally()
    },
    getPartnerStats() {
      useJwt
        .get('/qpartner/api/domain/partners/stats')
        .then(({ data }) => {
          this.partnerStats = data.data.partnerStats
        })
        .catch(e => console.error(e))
        .finally()
    },
    getCampaignStats() {
      useJwt
        .get('/qadv/api/domain/adv/stats')
        .then(({ data }) => {
          this.campaignsStats = data.data
        })
        .catch(e => console.error(e))
        .finally()
    },
    getAssetsStats() {
      useJwt
        .get('/qasset/api/domain/screens/stats')
        .then(({ data }) => {
          this.assetsStats = data.data
        })
        .catch(e => console.error(e))
        .finally()
    },
    syncApi() {
      this.getLocations()
      this.getPartnerStats()
      this.getCampaignStats()
      this.getAssetsStats()
    },
  },
}
</script>

<style lang="scss"></style>
